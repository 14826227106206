import * as React from 'react';
import { useState, useEffect } from 'react';
import MuseMatterport from '../images/muse-matterport-screenshot.jpg';
import Layout from '../components/Layout';
import MuseDentalClinic from '../images/muse-dental-clinic.jpg';
import SEO from '../components/Seo';

const Thanks = () => {

    const [showMatterport, setShowMatterport] = useState(false);

    return(
        <Layout>
             <SEO title="Muse Dental - Contact Us"/>
            <header>
                <div className="relative bg-muse-yellow">
                    <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-medium tracking-tight text-white sm:text-4xl lg:text-5xl text-center">Thank you for contacting us.</h1>
                        <h2 className="text-2xl font-medium tracking-tight text-white text-center">We will get back to you within 24 hours.</h2>
                    </div>
                </div>
            </header>

            <section>
            <div className="bg-gray-100">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="relative bg-white shadow-xl">
                    <h2 className="sr-only">Thanks for your submission</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div className="relative overflow-hidden py-10 px-6 bg-muse-yellow sm:px-10 xl:p-12">
                        <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                            <svg className="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
                            <defs>
                                <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#fff"></stop>
                                <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                </linearGradient>
                            </defs>
                            </svg>
                        </div>
                        <div className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden" aria-hidden="true">
                            <svg className="absolute inset-0 w-full h-full" width="359" height="339" viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
                            <defs>
                                <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#fff"></stop>
                                <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                </linearGradient>
                            </defs>
                            </svg>
                        </div>
                        <div className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
                            <svg className="absolute inset-0 w-full h-full" width="160" height="678" viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
                            <defs>
                                <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#fff"></stop>
                                <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                                </linearGradient>
                            </defs>
                            </svg>
                        </div>
                        <h3 className="text-2xl font-bold text-muse-teal">Contact Information</h3>
                        
                        <dl className="mt-8 space-y-6">
                            <dt><span className="sr-only">Adress</span></dt>
                            <dd className="flex text-base text-muse-teal">
                            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            </svg>
                            <span className="ml-3 text-muse-teal text-lg">Unit #3 - 9015 120th Street Delta, BC V4C 6R7</span>
                            </dd>

                            <dt><span className="sr-only">Phone number</span></dt>
                            <dd className="flex text-base">
                            <svg className="flex-shrink-0 w-6 h-6 text-muse-teal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                            <span className="ml-3 text-muse-teal text-lg">604-954-1223</span>
                            </dd>

                            <dt><span className="sr-only">Email</span></dt>
                            <dd className="flex text-base">
                            <svg className="flex-shrink-0 w-6 h-6 text-muse-teal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <a href="mailto:info@musedental.ca"className="ml-3 text-muse-teal text-lg hover:text-white">info@musedental.ca</a>
                            </dd>

                            <dt><span className="sr-only">Instagram</span></dt>
                            <dd className="flex text-base">
                            <svg className="h-7 w-7 text-muse-teal" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                            </svg>
                            <a href="https://www.instagram.com/muse_dental" target="_blank" className="ml-3 text-muse-teal text-lg hover:text-white">@musedental</a>
                            </dd>
                        </dl>
                        </div>

                        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <img src={MuseDentalClinic} className="w-full h-full object-cover"/>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section>                        
                <div className="max-w-full mx-auto">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.7528582352265!2d-122.8928793483359!3d49.16729908675787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d9a9b68ebc1b%3A0x81a641e4041a52a2!2s9015%20120%20St%2C%20Delta%2C%20BC%20V4C%206R7!5e0!3m2!1sen!2sca!4v1615686127590!5m2!1sen!2sca" width="100%" height="450" frameBorder="0" allowFullScreen></iframe>
                </div>
            </section>
        </Layout>
    )
}

export default Thanks;